<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
            v-if="unreadNotificationsCount > 0"
          >
            <template v-slot:badge>
              <span v-if="unreadNotificationsCount > 0 && unreadNotificationsCount < 5">{{ unreadNotificationsCount }}</span>
              <span v-else-if="unreadNotificationsCount >= 5">5+</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else>mdi-bell</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
      >
        <div>
          <app-bar-item
            v-for="(notification, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title :class="{'font-weight-bold': !notification.read}" @mouseover="markNotificationAsRead(notification)">
              {{ notification.text}}
            </v-list-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list width="150">
        <v-list-item to="/dashboard/settings">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click.prevent="logout">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import Notification from "@/models/Notification";
  import User from "@/models/User";

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState('dashboard', ['drawer']),
      notifications() {
        return Notification.query().orderBy('created', 'desc').all();
      },
      unreadNotificationsCount() {
        return Notification.query().where('read', false).count()
      }
    },

    methods: {
      ...mapMutations({
        setDrawer: 'dashboard/SET_DRAWER',
      }),

      logout: async function () {
        await User.api().logout()
        await this.$router.push('/account/login')
      },
      markNotificationAsRead(notification) {
        if (notification.read) {
          return
        }
        Notification.api().patch(`/api/v1/dashboard/notifications/${notification.id}/`, {read: true})
      }
    },
    created() {
      Notification.api().get('/api/v1/dashboard/notifications/?ordering=-created&page_size=5')
    }
  }
</script>
